<template lang="pug">
include ../../../configs/template
div
  div.w-100.d-flex.flex-wrap
    div.w-100
      +select('body.status_document','listStatuses', 'status_document', 'nameLang')
    div.w-100.mt-3
      v-btn(
        @click="saveCrewingStatementStatus"
        :loading="isLoading"
        color="success") {{ $t('save') }}
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'

export default {
  name: 'CrewingVerificationEditStatus',
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      isLoading: false,
      body: {
        status_document: null
      }
    }
  },
  computed: {
    ...mapState({
      nameLang: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    }),
    ...mapGetters(['statusChoose', 'statusById']),
    listStatuses () {
      let statusesArray = []
      statusesArray = this.statusChoose('StatementCrewing')
      statusesArray.push(this.statusById(34))
      return statusesArray
    }
  },
  mounted () {
    Object.keys(this.body).forEach(key => (this.body[key] = this.statement[key]))
  },
  methods: {
    ...mapActions(['updateCrewingVerificationStatement']),
    async saveCrewingStatementStatus () {
      this.isLoading = true
      const response = await this.updateCrewingVerificationStatement({ ...this.$route.params, body: this.body })
      if (SUCCESS_CODE.includes(response.code)) this.$notification.success('crewingVerification.edited')
      this.isLoading = false
    }
  }
}
</script>
